export default function useUrl() {
    const searchParams = Alpine.reactive(new URLSearchParams(window.location.search));

    /**
     * Update URL.
     */
    function updateUrl(replaceState) {
        let searchParamsString = searchParams.toString();
        if (searchParamsString.length) {
            searchParamsString = '?' + searchParamsString;
        }

        let newUrl =
            window.location.protocol + '//' + window.location.host + window.location.pathname + searchParamsString;

        if (replaceState === true) {
            window.history.replaceState({ path: newUrl }, '', newUrl);
        } else {
            window.history.pushState({ path: newUrl }, '', newUrl);
        }
    }

    /**
     * Append URL param.
     */
    function appendUrlParam(key, value, replaceState) {
        searchParams.append(key, value);
        updateUrl(replaceState);
    }

    /**
     * Set URL param.
     */
    function setUrlParam(key, value, replaceState) {
        searchParams.set(key, value);
        updateUrl(replaceState);
    }

    /**
     * Get URL has param.
     */
    function getUrlParam(key) {
        return searchParams.get(key);
    }

    /**
     * Unset URL param.
     */
    function unsetUrlParam(key, replaceState) {
        searchParams.delete(key);
        updateUrl(replaceState);
    }

    /**
     * Check if URL has param.
     */
    function hasUrlParam(key) {
        return searchParams.has(key);
    }

    return {
        searchParams,

        setUrlParam,
        unsetUrlParam,
        getUrlParam,
        hasUrlParam,
        appendUrlParam,
    };
}
